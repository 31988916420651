import { Popover } from "flowbite";
import { Controller } from "@hotwired/stimulus";

// Add data-popover and data-visibility="invisible" to the popover element
// Add data-action="click->[controller-name]#togglePopover touchstart->[controller-name]#togglePopover to the trigger element

export default class extends Controller {
  static targets = ["trigger", "popover"];

  get popoverOptions() {
    return {
      placement: "bottom",
      triggerType: "click",
      offset: 7,
      onHide: () => {
        this.setVisibility("invisible");
      },
      onShow: () => {
        this.setVisibility("visible");
      },
    };
  }

  connect() {
    if (!this.popoverInstance) {
      this.popoverInstance = new Popover(
        this.popoverTarget,
        this.triggerTarget,
        this.popoverOptions,
      );
    }
    this.popoverInstance.hide();
  }

  disconnect() {
    if (this.popoverInstance) {
      this.popoverInstance.destroy();
      this.popoverInstance = null;
    }
  }

  // Internal functions
  getVisibility() {
    return this.popoverTarget.getAttribute("data-visibility");
  }

  setVisibility(value) {
    this.popoverTarget.setAttribute("data-visibility", value);
  }

  toggleVisibility() {
    if (this.getVisibility() === "visible") {
      this.setVisibility("invisible");
    } else {
      this.setVisibility("visible");
    }
  }

  // Event handler
  togglePopover(event) {
    if (this.popoverInstance.isVisible()) {
      if (this.getVisibility() === "invisible") {
        this.popoverInstance.hide();
      }
    } else {
      this.popoverInstance.show();
    }
    this.toggleVisibility();
    event.stopImmediatePropagation();
  }
}
