import FlowbitePopoverController from "./flowbite/flowbite_popover_controller";
import { setUrlParameterAndReload } from "../src/custom/UrlParams";

export default class extends FlowbitePopoverController {
  // Event handlers
  changeLanguage(event) {
    event.preventDefault();
    setUrlParameterAndReload("locale", event.params.language);
    event.stopImmediatePropagation();
  }
}
